<template>
  <div class="sm:px-12">
    <div
      class="w-full h-full p-8 md:p-12 bg-indigo-50 dark:bg-indigo-900 sm:rounded-2xl pattern-bg"
    >
      <div class="w-full h-5/6 grid grid-cols-6 gap-12">
        <div class="col-span-6 md:col-span-3 self-center py-24">
          <h1
            class="text-6xl font-extrablack text-indigo-900 dark:text-indigo-200 mb-4"
          >
            Endless Stories,<br />
            Endless Smiles
          </h1>
          <h2
            class="text-2xl font-medium text-slate-500 dark:text-indigo-300 mb-4"
          >
            Unleash a world of imagination with <br />endless custom bedtime
            stories for your child.
          </h2>
          <ButtonPrimary @click="goToConnect" size="lg" class="mt-8">
            Create free account
          </ButtonPrimary>
        </div>
        <div class="col-span-6 md:col-span-3 items-center">
          <div
            class="aspect-[3/4] w-full overflow-y-auto bg-white dark:bg-indigo-50 shadow-2xl shadow-slate-300 dark:shadow-indigo-900 rounded-md prose lg:prose-lg p-16 font-serif"
          >
            <h1>This is a title</h1>
            <p>
              Hello little ones! Do you ever look up in the sky and see planes
              flying high? Have you ever wondered how they can stay up there for
              so long? Well, let me tell you!
            </p>
            <p>
              Planes have wings that are shaped like a bird's wings. The wings
              help lift the plane up into the air.
            </p>
            <p>
              They use something called "lift" to do this. Lift is like a magic
              force that makes things fly. The wings make lift by moving through
              the air.
            </p>
            <p>
              But, how do planes move through the air? Planes have special
              engines that help them move forward. The engines push the plane
              forward and the wings use the lift to keep the plane up in the
              air.
            </p>
            <p>
              Once the plane is up in the air, the pilot can control it with
              something called a cockpit. The cockpit is like a room where the
              pilot sits and controls the plane. They use special controls to
              make the plane go up, down, left, and right.
            </p>
            <p>
              Flying in a plane can be a little bumpy sometimes, but it's just
              like riding on a roller coaster in the sky! And when you land,
              it's just like coming in for a big hug.
            </p>
            <p>
              So, that's how planes fly! It's like magic, but it's really just
              science. The next time you see a plane in the sky, you'll know how
              it got up there and how it stays up there. It's pretty amazing,
              isn't it?
            </p>
          </div>
        </div>
        <!-- <div class="col-span-6 md:col-span-1 text-slate-400">
          <p>type of story</p>
          <p>subject of the story</p>
          <p>age group</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
definePageMeta({
  layout: "homepage",
  middleware: ["auth"],
});
const router = useRouter();

const goToConnect = () => {
  router.push("/create-account");
};
</script>
